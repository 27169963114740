import React,{ useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import storage from "../services/storage";
import Home from "../Pages/Home";
import Sobre from "../Pages/Sobre";
import Diretores from "../Pages/Diretores"; 
import ErikTrovilho from "../Pages/Diretores/ErikTrovilho";
import RaphaBorges from "../Pages/Diretores/RaphaBorges";
import Projetos from "../Pages/Projetos";
import Contato from "../Pages/Contato";

export default function Rotas() {
  useEffect(()=>{

    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/sobre" element={<Sobre />} />
        <Route exact path="/diretores" element={<Diretores />} />
        <Route exact path="/diretores/erik-trovilho" element={<ErikTrovilho />} />
        <Route exact path="/diretores/rapha-borges" element={<RaphaBorges />} />
        <Route exact path="/projetos" element={<Projetos />} />
        <Route exact path="/contato" element={<Contato />} />
      </Routes>
    </>
  );
}
