import React, { useEffect, useRef, useState } from "react";

import videoDiretor2 from "../../../assets/mp4/videoDiretor2.mp4";
import raphaBorges1 from "../../../assets/png/raphaBorges1.png";
import raphaBorges2 from "../../../assets/png/raphaBorges2.png";
import raphaBorges3 from "../../../assets/png/raphaBorges3.png";
import raphaBorges4 from "../../../assets/png/raphaBorges4.png";
import raphaBorges5 from "../../../assets/png/raphaBorges5.png";
import raphaBorges6 from "../../../assets/png/raphaBorges6.png";
import "./index.css";
import Header from "../../Header";
import ScrollAnimado from "../../ScrollAnimado";

import ModalVideo from "../../ModalVideo";
import Footer from "../../Footer";

export default function RaphaBorges() {
  const videoRef = useRef(null);

  const [linkVideo, setLinkVideo] = useState("");

  const vaiAte = () => {
    const divPosition = document.getElementById(
      "projetosRaphaBorges"
    ).offsetTop;
    window.scrollTo({
      top: divPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      vaiAte();
    }, 500);
  }, []);
  return (
    <div style={{ backgroundColor: "#000000" }}>
      <div className="w-100 h-100 position-relative">
        <video autoPlay loop muted className="videoBackground">
          <source src={videoDiretor2} type="video/mp4" />
          Seu navegador não suporta vídeos HTML5.
        </video>
        <Header />
        <div className="tituloRaphaBorges">
          Rapha <br />
          Borges
        </div>
        <ScrollAnimado />
      </div>
      <ModalVideo linkVideo={linkVideo} setLinkVideo={setLinkVideo} />
      <div className="divCapaProjetos" id="projetosRaphaBorges">
        <div className="row p-0 m-0">
          <div className="col-md-6 p-0 ">
            {/* <video
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/367344666?h=f34bafdd90"
                );
              }}
              ref={videoRef}
              loop
              muted
              className="capaProjeto"
              onMouseOver={() => {
                videoRef.current.play();
              }}
              onMouseOut={() => {
                videoRef.current.pause();
              }}
            >
              <source src={videoDiretor2} type="video/mp4" />
              Seu navegador não suporta vídeos HTML5.
            </video> */}
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/367344666?h=f34bafdd90"
                );
              }}
              className="capaProjeto"
              src={raphaBorges1}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">
              Sabot x Supanova
            </div>
          </div>
          <div className="col-md-6 p-0  ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/726224751?h=fbd573cabf"
                );
              }}
              className="capaProjeto"
              src={raphaBorges2}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">
              Victor Otaviano feat. Catarse
            </div>
          </div>
        </div>
        <div className="row p-0 m-0">
          <div className="col-md-6 p-0  ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/726822084?h=3ef72574e0"
                );
              }}
              className="capaProjeto"
              src={raphaBorges3}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">
              Casa vogue feat. Ana Hickamn
            </div>
          </div>
          <div className="col-md-6 p-0 ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/726220528?h=4330e0741d"
                );
              }}
              className="capaProjeto"
              src={raphaBorges4}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">
              Estúdio Guto Requena feat. Chandon
            </div>
          </div>
        </div>
        <div className="row p-0 m-0">
          <div className="col-md-6 p-0 ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/726221424?h=1dc6b08298"
                );
              }}
              className="capaProjeto"
              src={raphaBorges5}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">
              New Era Inverno 22
            </div>
          </div>
          <div className="col-md-6 p-0 ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/726223765?h=c928978b92"
                );
              }}
              className="capaProjeto"
              src={raphaBorges6}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">
              Idea Zarvos feat A.R Arquiteros
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center w-100">
        <div style={{ maxWidth: 1000 }}>
          <div className="container">
            <div className="tituloRaphaBorgesDetalhes">Rapha Borges</div>
          </div>
          <div className="container d-flex justify-content-between flex-column align-items-center">
            <div className="textoRaphaBorgesDetalhes">
              Raphael Borges, começou seu caminho no jornalismo diário,
              trabalhando como cinegrafista de rua, com pautas extensas e uma
              vontade de transparecer a notícia sem texto.
              <br />
              <br />
              Passou por direção de fotografia de um longa, curtas para
              festivais, coberturas internacionais, editoriais de moda e várias
              roubadas que o mercado audiovisual proporciona e ensina a fazer de
              tudo um pouco.
              <br />
              <br />
              Seus trabalhos são marcados pela luz, com grande influência da
              arquitetura, Raphael já trabalhou em projetos com a Casa Vogue,
              New Era Brasil e Tidal.
              <br />
              <br />
              <br />
              <br />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
