import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Header from "../Header";
import "./index.css";
import Footer from "../Footer";
import RedesSociais from "../RedesSociais";

export default function Contato() {
  const form = useRef();
  const [enviado, setEnviado] = useState(false);

  const fnEnviarFormulario = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_4hfivsg",
        "template_vold7fb",
        form.current,
        "rwqOaaFi-CbganR0P"
      )

      // ricardozuqueto
      // ricardozuqueto
      // ricardozuqueto
      // ricardozuqueto
      // ricardozuqueto
      // .sendForm(
      //   "service_7z5wrzm",
      //   "template_a6q4hfl",
      //   form.current,
      //   "bMRy8pLjmMRAas5jO"
      // )
      .then(
        (result) => {
          setEnviado(true);
          setTimeout(() => {
            setEnviado(false);
          }, 5000);
          form.current.reset();
        },
        (error) => {
          console.error(error);
        }
      );
  };

  return (
    <>
      <Header />
      <div className="divContato ">
        <div
          className="row"
          style={{ marginTop: 160, maxWidth: 1500, width: "100%" }}
        >
          <div className="col-md-6 d-flex justify-content-center align-items-center p-4">
            <div className="divTextoContato">
              <div className="tituloContato">Fala com a gente, bb</div>
              <div className="textoContato">
                Quer fazer um filme maneiro pro seu cliente ou para o seu
                próprio negócio? Manda uma breve mensagem contanto do projeto e
                entraremos em contato!
                <br />
                <br />
                <br />
                <RedesSociais />
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center p-4">
            {enviado ? (
              <div className="mensagemEnviada">
                Mensagem enviada com sucesso!
              </div>
            ) : (
              <form
                ref={form}
                onSubmit={fnEnviarFormulario}
                className="divFormulario"
              >
                <label className="tituloContatoFormulario mt-5 mt-md-0">
                  E-mail
                </label>
                <input className="emailInput" type="email" name="email" />

                <label className="tituloContatoFormulario mt-4">Message</label>
                <textarea className="mensagemInput" name="message" />
                <div className="d-flex justify-content-end mt-4">
                  <input
                    className="botaoFormulario"
                    type="submit"
                    value="Enviar"
                  />
                </div>
              </form>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
