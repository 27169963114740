import React, { useEffect, useRef, useState } from "react";
import "./index.css"; 

export default function SobreHome() {
  return (
    <>
      <div className="divSobreHome d-flex d-md-none">
        <div className="container d-flex justify-content-between flex-column align-items-center">
          <div className="textoSobreHome">
            A FILO é uma companhia de produção audiovisual que transita pelos
            campos artístico e comercial a fim de realizar projetos
            independentes e publicitários, estimulando a criatividade e
            viabilizando expressões e visões artísticas.
            <br />
            <br />
            Somos um espaço que visa unir profissionais de diferentes
            trajetórias com projetos que refletem suas paixões e afinidades,
            resultando em trabalhos especializados e precisos.
          </div>
        </div>
      </div>
    </>
  );
}
