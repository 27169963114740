import React from "react";
import "./index.css";
import logoFooter from "../../assets/svg/logoFooter.svg";
export default function Footer() {
  return (
    <div className="divFooter">
      <div className="d-flex justify-content-center pt-3">
        <img src={logoFooter} alt="logoFooter" />
      </div>
      <div className="textoFooter d-none d-md-flex">
        <div style={{ minWidth: 240 }}>
          <span className="textoFooterMenor"> FONE </span> +55 (ll) 916 719 128
        </div>
        <div style={{ minWidth: 280 }}>
          <span className="textoFooterMenor"> EMAIL </span> ADM@FILOFILMES.ART
        </div>
        <div style={{ minWidth: 170 }}>
          <span className="textoFooterMenor"> VIMEO </span> /FILOFILMES
        </div>
        <div style={{ minWidth: 200 }}>
          <span className="textoFooterMenor"> INSTA </span> @ARROBAFILO
        </div>
      </div>
      <div className="textoFooterMobile d-flex d-md-none">
        <div style={{ padding:'0 10px' }}>
          <span className="textoFooterMenorMobile"> FONE </span> +55 (ll) 916 719 128
        </div>
        <div style={{ padding:'0 10px' }}>
          <span className="textoFooterMenorMobile"> EMAIL </span> ADM@FILOFILMES.ART
        </div>
        <div style={{ padding:'0 10px' }}>
          <span className="textoFooterMenorMobile"> VIMEO </span> /FILOFILMES
        </div>
        <div style={{ padding:'0 10px' }}>
          <span className="textoFooterMenorMobile"> INSTA </span> @ARROBAFILO
        </div>
      </div>
    </div>
  );
}
