import React, { useEffect, useRef, useState } from "react";

import raphaBorges1 from "../../../assets/png/raphaBorges1.png";
import raphaBorges2 from "../../../assets/png/raphaBorges2.png";
import raphaBorges3 from "../../../assets/png/raphaBorges3.png";
import raphaBorges4 from "../../../assets/png/raphaBorges4.png";
import raphaBorges5 from "../../../assets/png/raphaBorges5.png";
import raphaBorges6 from "../../../assets/png/raphaBorges6.png";
import erikTrovilho1 from "../../../assets/png/erikTrovilho1.png";
import erikTrovilho2 from "../../../assets/png/erikTrovilho2.png";
import erikTrovilho3 from "../../../assets/png/erikTrovilho3.png";
import erikTrovilho4 from "../../../assets/png/erikTrovilho4.png";
import erikTrovilho5 from "../../../assets/png/erikTrovilho5.png";
import erikTrovilho6 from "../../../assets/png/erikTrovilho6.png";

import logoBackground from "../../../assets/svg/logoBackground.svg";
import textoFilmesBackground from "../../../assets/svg/textoFilmesBackground.svg";
import "./index.css";
import ScrollAnimado from "../../ScrollAnimado";

import ModalVideo from "../../ModalVideo";

export default function ProjetosHome() {
  const videoRef = useRef(null);

  const [linkVideo, setLinkVideo] = useState("");

  return (
    <>
      <ModalVideo linkVideo={linkVideo} setLinkVideo={setLinkVideo} />
      <div className="d-flex d-md-none flex-column">
        <div className="w-100 h-100">
          <div className="divCapaTodosProjetoHome ">
            <div className="row">
              <div className="col-md-6 d-flex justify-content-center  ">
                <img
                  className="logoBackgroundHome"
                  src={logoBackground}
                  alt="capa do projeto"
                />
              </div>
              <div className="col-md-6 d-flex justify-content-center  ">
                <img
                  className="textoFilmesBackgroundCimaFixoHome"
                  src={textoFilmesBackground}
                  alt="capa do projeto"
                />
              </div>
            </div>

            <img
              className="textoFilmesBackgroundBaixoHome d-md-flex d-none"
              src={textoFilmesBackground}
              alt="capa do projeto"
            />
            {/* <ScrollAnimado /> */}
          </div>
        </div>
        <div className="divCapaTodosProjetoHome  overflow-hidden ">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/809801311?h=5e1ac78efc"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "25% 0 0 0" }}
              >
                <img src={erikTrovilho1} alt="capa do projeto" />

                <div className="tituloCardTodosProjetoHome text-center   d-flex d-md-none">
                  Sport Nutrition
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/797771115?h=875c0628ce"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "0 0 25% 0" }}
              >
                <img src={erikTrovilho2} alt="capa do projeto" />

                <div className="tituloCardTodosProjetoHome text-center   d-flex d-md-none">
                  Bodycation
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/809801440?h=c04a5785de"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "24px 0 24px 25%" }}
              >
                <img src={erikTrovilho3} alt="capa do projeto" />
                <div className="tituloCardTodosProjetoHome text-center   d-flex d-md-none">
                  Mobly
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/809801590?h=2eed9c6f58"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "0 0 25% 0" }}
              >
                <img src={erikTrovilho4} alt="capa do projeto" />
                <div className="tituloCardTodosProjetoHome text-center   d-flex d-md-none">
                  Monster Energy
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/809801504?h=d08d1a0f73"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "25% 0 0 0" }}
              >
                <img src={erikTrovilho5} alt="capa do projeto" />
                <div className="tituloCardTodosProjetoHome text-center  d-flex d-md-none">
                  Sóciro / Unfinished Festival
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/809801384?h=97a89d41a0"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "24px 0 24px 25%" }}
              >
                <img src={erikTrovilho6} alt="capa do projeto" />
                <div className="tituloCardTodosProjetoHome text-center  d-flex d-md-none">
                  High Burger GTA
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/367344666?h=f34bafdd90"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "25% 0 0 0" }}
              >
                <img src={raphaBorges1} alt="capa do projeto" />
                <div className="tituloCardTodosProjetoHome text-center  d-flex d-md-none">
                  Sabot X Supanova
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/726224751?h=fbd573cabf"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "0 0 25% 0" }}
              >
                <img src={raphaBorges2} alt="capa do projeto" />
                <div className="tituloCardTodosProjetoHome text-center  d-flex d-md-none">
                  Victor Otaviano feat. Catarse
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-center justify-content-start">
            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/726822084?h=3ef72574e0"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "24px 0 12px 33%" }}
              >
                <img src={raphaBorges3} alt="capa do projeto" />
                <div className="tituloCardTodosProjetoHome text-center  d-flex d-md-none">
                  Casa vogue feat. Ana Hickamn
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/726220528?h=4330e0741d"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "12px 0 24px 66%" }}
              >
                <img src={raphaBorges4} alt="capa do projeto" />
                <div className="tituloCardTodosProjetoHome text-center  d-flex d-md-none">
                  Estúdio Guto Requena feat. Chandon
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div
                onClick={() => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/726221424?h=1dc6b08298"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "0 0 25% 0" }}
              >
                <img src={raphaBorges5} alt="capa do projeto" />
                <div className="tituloCardTodosProjetoHome text-center  d-flex d-md-none">
                  New Era Inverno 22
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div
                onClick={(e) => {
                  setLinkVideo(
                    "https://player.vimeo.com/video/726223765?h=c928978b92"
                  );
                }}
                className="capaTodosProjetoHome d-flex flex-column w-100"
                style={{ margin: "25% 0 0 0" }}
              >
                <img src={raphaBorges6} alt="capa do projeto" />
                <div className="tituloCardTodosProjetoHome text-center  d-flex d-md-none">
                  Idea Zarvos feat A.R Arquiteros
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
