import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Header from "../Header";
import Footer from "../Footer";

export default function Sobre() {
  return (
    <>
      <div className="divSobre">
        <Header />
        <div className="container d-flex justify-content-between flex-column align-items-center min-vh-100">
          <div className="textoSobre">
            A FILO é uma companhia de produção audiovisual que transita pelos
            campos artístico e comercial a fim de realizar projetos
            independentes e publicitários, estimulando a criatividade e
            viabilizando expressões e visões artísticas.
            <br />
            <br />
            Somos um espaço que visa unir profissionais de diferentes
            trajetórias com projetos que refletem suas paixões e afinidades,
            resultando em trabalhos especializados e precisos.
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
