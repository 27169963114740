import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import logo from "../../assets/svg/logo.svg";
import { Spin as Hamburger } from "hamburger-react";
import { slide as Menu } from "react-burger-menu";
import RedesSociais from "../RedesSociais";
import { Link, useNavigate } from "react-router-dom";

export default function Header({ vaiAte }) {
  const [menuAberto, setMenuAberto] = useState(false);
  const navigator = useNavigate();
  window.addEventListener("scroll", () => {
    setMenuAberto(false);
  });
  var url_atual = window.location.pathname;

  useEffect(() => {
    url_atual = window.location.pathname;
  }, [window.location.pathname]);

  return (
    <>
      {url_atual === "/sobre" && !menuAberto && (
        <div className="tituloPagina pe-4" style={{ color: "#F2F2E7" }}>
          sobre nós
        </div>
      )}
      {url_atual === "/diretores" && !menuAberto && (
        <div className="tituloPagina pe-4 " style={{ color: "#F2F2E7" }}>
          diretores
        </div>
      )}
      {url_atual === "/diretores/erik-trovilho" && !menuAberto && (
        <div className="tituloPagina pe-4 " style={{ color: "#F2F2E7" }}>
          Erik Trovilho
        </div>
      )}
      {url_atual === "/diretores/rapha-borges" && !menuAberto && (
        <div className="tituloPagina pe-4 " style={{ color: "#F2F2E7" }}>
          Rapha Borges
        </div>
      )}
      {url_atual === "/projetos" && !menuAberto && (
        <div className="tituloPagina pe-4 " style={{ color: "#F2F2E7" }}>
          projetos
        </div>
      )}
      {url_atual === "/contato" && !menuAberto && (
        <div className="tituloPagina pe-4 " style={{ color: "#F2F2E7" }}>
          contato
        </div>
      )}
      <div className="todoHeader">
        <div
          className={
            "tituloMenu d-none d-md-flex " +
            (menuAberto ? "" : "moveTituloMenu")
          }
        >
          menu
        </div>
        <Menu
          customBurgerIcon={
            <Hamburger
              color="#F2F2E7"
              duration={0.5}
              toggled={menuAberto}
              size={30}
              direction="right"
              toggle={setMenuAberto}
            />
          }
          className="position-absolute top-0 left-0"
          isOpen={menuAberto}
          onClose={() => {
            setMenuAberto(!menuAberto);
          }}
          onOpen={() => {
            setMenuAberto(!menuAberto);
          }}
        >
          {/* <div className="d-none d-md-flex flex-column"> */}
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Link to={"/projetos"}>
                <div className="itemMenu mt-5">Projetos</div>
              </Link>
              <Link to={"/diretores"}>
                <div className="itemMenu ">Diretores</div>
              </Link>
              <Link to={"/sobre"}>
                <div className="itemMenu ">Sobre</div>
              </Link>
              <Link to={"/contato"}>
                <div className="itemMenu">Contato</div>
              </Link>
            </div>
            <div className=" itemMenu mb-3">
              <RedesSociais />
            </div>
          </div>
          {/* </div> */}

          {/* <div className="d-md-none d-flex flex-column">
          <div
            className="itemMenu mt-5"
            onClick={() => {
              vaiAte("sobreHome");
            }}
          >
            Sobre
          </div>

          <div
            className="itemMenu"
            onClick={() => {
              navigator("/");
              setTimeout(() => {
                vaiAte("diretoresHome");
              }, 10);
            }}
          >
          Diretores
          </div>
          <div
            className="itemMenu"
            onClick={() => {
              vaiAte("projetosHome");
            }}
          >
            Projetos
            </div>

          <div
          className="itemMenu"
            onClick={() => {
              vaiAte("contatoHome");
            }}
          >
            Contato
          </div>
        </div> */}
        </Menu>

        <Link to={"/"}>
          <img className="logo" src={logo} alt="logotipo" />
        </Link>
      </div>
    </>
  );
}
