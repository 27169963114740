import React, { useEffect, useRef, useState } from "react";
import "../index.css";
import videoHome1 from "../../../assets/mp4/videoHome1.mp4";

import ScrollAnimado from "../../ScrollAnimado";
import ModalVideo from "../../ModalVideo";

export default function Home1() {
  const [menuAberto, setMenuAberto] = useState(false);
  const [linkVideo, setLinkVideo] = useState("");
  window.addEventListener("scroll", () => {
    setMenuAberto(false);
  });

  return (
    <>
      <ModalVideo linkVideo={linkVideo} setLinkVideo={setLinkVideo} />
      <div className="w-100 h-100 position-relative">
        <video autoPlay loop muted className="videoBackground">
          <source src={videoHome1} type="video/mp4" />
          Seu navegador não suporta vídeos HTML5.
        </video>

        <div
          className="tituloHome tituloHome1 "
          onClick={() => {
            setLinkVideo(
              "https://player.vimeo.com/video/797771115?h=875c0628ce"
            );
          }}
        >
          Bodyaction
        </div>

        <div className="background"></div>
       
      </div>
    </>
  );
}
