import React, { useEffect, useRef, useState } from "react";
import videoHome4 from "../../../assets/mp4/videoHome4.mp4";
import "../index.css";
import ModalVideo from "../../ModalVideo";
export default function Home4() {
  const [linkVideo, setLinkVideo] = useState("");
  return (
    <>
      <ModalVideo linkVideo={linkVideo} setLinkVideo={setLinkVideo} />
      <div className="w-100 h-100 position-relative">
        <video autoPlay loop muted className="videoBackground">
          <source src={videoHome4} type="video/mp4" />
          Seu navegador não suporta vídeos HTML5.
        </video>

        <div className="container">
          <div
            className="tituloHome tituloHome1 text-center"
            onClick={() => {
              setLinkVideo(
                "https://player.vimeo.com/video/726221424?h=1dc6b08298"
              );
            }}
          >
            New Era
          </div>
          <div className="background"></div>
        </div>
      </div>
    </>
  );
}
