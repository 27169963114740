import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./index.css";
import RedesSociais from "../../RedesSociais";

export default function ContatoHome() {
  const form = useRef();
  const [enviado, setEnviado] = useState(false);

  const fnEnviarFormulario = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ez3s723",
        "template_a6q4hfl",
        form.current,
        "bMRy8pLjmMRAas5jO"
      )
      .then(
        (result) => {
          setEnviado(true);
          setTimeout(() => {
            setEnviado(false);
          }, 5000);
          form.current.reset();
        },
        (error) => {
          console.error(error);
        }
      );
  };

  return (
    <>
      <div className="divContatoHome ">
        <div
          className="row"
          style={{ marginTop: 60, maxWidth: 1500, width: "100%" }}
        >
          <div className="col-md-6 d-flex justify-content-center align-items-center p-4">
            <div className="divTextoContatoHome">
              <div className="tituloContatoHome">Fala com a gente, bb</div>
              <div className="textoContatoHome">
                Quer fazer um filme maneiro pro seu cliente ou para o seu
                próprio negócio? Manda uma breve mensagem contanto do projeto e
                entraremos em contato!
                <br />
                <br />
                <br />
                adm@filofilmes.art
                <br />
                +55 11 91671-9128
                <br />
                <br />
                <br />
                <RedesSociais />
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center p-4">
            {enviado ? (
              <div className="mensagemEnviadaHome">
                Mensagem enviada com sucesso!
              </div>
            ) : (
              <form
                ref={form}
                onSubmit={fnEnviarFormulario}
                className="divFormularioHome"
              >
                <label className="tituloContatoFormularioHome mt-5 mt-md-0">
                  E-mail
                </label>
                <input className="emailInputHome" type="email" name="email" />

                <label className="tituloContatoFormularioHome mt-4">
                  Message
                </label>
                <textarea className="mensagemInputHome" name="message" />
                <div className="d-flex justify-content-end mt-4">
                  <input
                    className="botaoFormularioHome"
                    type="submit"
                    value="Enviar"
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
