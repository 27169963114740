import React, { useEffect, useRef, useState } from "react";
import videoDiretor1 from "../../assets/mp4/videoDiretor1.mp4";
import videoDiretor2 from "../../assets/mp4/videoDiretor2.mp4";
import "./index.css";
import Header from "../Header";
import { Link } from "react-router-dom";
import ScrollAnimado from "../ScrollAnimado";
export default function Diretores() {
  return (
    <>
      <div className="w-100 h-100 position-relative">
        <video autoPlay loop muted className="videoBackground">
          <source src={videoDiretor1} type="video/mp4" />
          Seu navegador não suporta vídeos HTML5.
        </video>
        <Header />



        <Link to={"/diretores/erik-trovilho"}>
          <div className="tituloDiretor">
            Erik <br />
            Trovilho
          </div>
        </Link>


        
      </div>
      <ScrollAnimado />
      <div className="w-100 h-100 position-relative">
        <video autoPlay loop muted className="videoBackground">
          <source src={videoDiretor2} type="video/mp4" />
          Seu navegador não suporta vídeos HTML5.
        </video>
        <Link to={"/diretores/rapha-borges"}>
          <div className="tituloDiretor">
            Rapha <br />
            Borges
          </div>
        </Link>
      </div>
    </>
  );
}
