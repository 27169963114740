import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Home1 from "./Home1";
import Home2 from "./Home2";
import Home3 from "./Home3";
import Home4 from "./Home4";
import Home5 from "./Home5";
import Home6 from "./Home6";
import Footer from "../Footer";
import SobreHome from "./SobreHome";
import DiretoresHome from "./DiretoresHome";
import Header from "../Header";
import ProjetosHome from "./ProjetosHome";
import ContatoHome from "./ContatoHome";
import Home0 from "./Home0";

export default function Home() {
  const [itemClick, setItemClick] = useState("");
  const ref = useRef(null);

  const vaiAte = (local) => {
    const divPosition = document.getElementById(local).offsetTop;
    window.scrollTo({
      top: divPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Header vaiAte={vaiAte} />
      <Home0 />
      <Home1 />
      <Home2 />
      <Home3 />
      <Home4 />
      <Home5 />
      {/* <Home6 /> */}
      <div id="sobreHome" className="d-flex d-md-none flex-column">
        <SobreHome />
      </div>
      <div id="diretoresHome" className="d-flex d-md-none flex-column">
        <DiretoresHome />
      </div>
      <div id="projetosHome">
        <ProjetosHome />
      </div>
      <div id="contatoHome" className="d-flex d-md-none flex-column">
        <ContatoHome />
      </div>
      <Footer />
    </>
  );
}
