import React, { useEffect, useRef, useState } from "react";
import videoHome7 from "../../../assets/mp4/videoHome7.mp4";
import "../index.css";
import ModalVideo from "../../ModalVideo";
import ScrollAnimado from "../../ScrollAnimado";
export default function Home0() {
  const [linkVideo, setLinkVideo] = useState("");
  return (
    <>
      <ModalVideo linkVideo={linkVideo} setLinkVideo={setLinkVideo} />
      <div className="w-100 h-100 position-relative">
        <video autoPlay loop muted className="videoBackground">
          <source src={videoHome7} type="video/mp4" />
          Seu navegador não suporta vídeos HTML5.
        </video>

        <div className="container">
          <div
            className="tituloHome tituloHome1 text-center"
            onClick={() => {
              setLinkVideo(
                "https://player.vimeo.com/video/903101691?h=55472d4911"
              );
            }}
          >
            Rexona
          </div>
          <div className="background"></div>
          <ScrollAnimado />
        </div>
      </div>
    </>
  );
}
