import React, { useEffect, useRef, useState } from "react";

import videoDiretor1 from "../../../assets/mp4/videoDiretor1.mp4";
import erikTrovilho1 from "../../../assets/png/erikTrovilho1.png";
import erikTrovilho2 from "../../../assets/png/erikTrovilho2.png";
import erikTrovilho3 from "../../../assets/png/erikTrovilho3.png";
import erikTrovilho4 from "../../../assets/png/erikTrovilho4.png";
import erikTrovilho5 from "../../../assets/png/erikTrovilho5.png";
import erikTrovilho6 from "../../../assets/png/erikTrovilho6.png";
import erikTrovilho9 from "../../../assets/png/erikTrovilho9.png";
import "./index.css";
import Header from "../../Header";
import ScrollAnimado from "../../ScrollAnimado";

import ModalVideo from "../../ModalVideo";
import Footer from "../../Footer";

export default function ErikTrovilho() {
  const videoRef = useRef(null);

  const [linkVideo, setLinkVideo] = useState("");

  const vaiAte = () => {
    const divPosition = document.getElementById(
      "projetosErickTrovilho"
    ).offsetTop;
    window.scrollTo({
      top: divPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      vaiAte();
    }, 500);
  }, []);
  return (
    <div style={{ backgroundColor: "#000000" }}>
      <div className="w-100 h-100 position-relative">
        <video autoPlay loop muted className="videoBackground">
          <source src={videoDiretor1} type="video/mp4" />
          Seu navegador não suporta vídeos HTML5.
        </video>
        <Header />
        <div className="tituloErikTrovilho">
          Erik <br />
          Trovilho
        </div>
        <ScrollAnimado />
      </div>
      <ModalVideo linkVideo={linkVideo} setLinkVideo={setLinkVideo} />
      <div className="divCapaProjetos" id="projetosErickTrovilho">
        <div className="row p-0 m-0">
          <div className="col-md-6 p-0  ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/903103927?h=8bae5a953d"
                );
              }}
              className="capaProjeto"
              src={erikTrovilho9}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">Rexona</div>
          </div>
          <div className="col-md-6 p-0 ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/809801311?h=5e1ac78efc"
                );
              }}
              className="capaProjeto"
              src={erikTrovilho1}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">
              Sport Nutrition
            </div>
          </div>
        </div>
        <div className="row p-0 m-0">
          <div className="col-md-6 p-0  ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/797771115?h=875c0628ce"
                );
              }}
              className="capaProjeto"
              src={erikTrovilho2}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">Bodycation</div>
          </div>

          <div className="col-md-6 p-0 ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/809801590?h=2eed9c6f58"
                );
              }}
              className="capaProjeto"
              src={erikTrovilho4}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">
              Monster Energy
            </div>
          </div>
        </div>
        <div className="row p-0 m-0">
          <div className="col-md-6 p-0 ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/809801504?h=d08d1a0f73"
                );
              }}
              className="capaProjeto"
              src={erikTrovilho5}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">
              Sóciro / Unfinished Festival{" "}
            </div>
          </div>
          <div className="col-md-6 p-0 ">
            <img
              onClick={() => {
                setLinkVideo(
                  "https://player.vimeo.com/video/809801384?h=97a89d41a0"
                );
              }}
              className="capaProjeto"
              src={erikTrovilho6}
              alt="capa do projeto"
            />
            <div className="tituloCardProjeto d-flex d-md-none">
              High Burger GTA
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center w-100">
        <div style={{ maxWidth: 1000 }}>
          <div className="container">
            <div className="tituloErikTrovilhoDetalhes">Erik Trovilho</div>
          </div>
          <div className="container d-flex justify-content-between flex-column align-items-center">
            <div className="textoErikTrovilhoDetalhes">
              Erik Trovilho, ou “Trovis” é fundador da FILO filmes e iniciou sua
              trajetória como videomaker e editor, registrando atletas e eventos
              esportivos pelo Brasil e pelo mundo.A partir dessas experiências
              descobriu o quão transformador é trabalhar com o audiovisual.
              <br />
              <br />
              Durante seu caminho como diretor, pôde desenvolver projetos como
              clipes, curtas, documentários, e campanhas publicitárias. Sua
              estética segue uma temática documental, trabalhando a partir de
              registros - com pouca interferência nas cenas; a captura de
              momentos únicos imprime em seus filmes um tom de autenticidade que
              só ocorre no acaso.
              <br />
              <br />
              <br />
              <br />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
