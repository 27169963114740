import React, { useEffect, useRef, useState } from "react";
import videoHome2 from "../../../assets/mp4/videoHome2.mp4";
import "../index.css";
import ModalVideo from "../../ModalVideo";
export default function Home2() {
  const [linkVideo, setLinkVideo] = useState("");
  return (
    <>
     <ModalVideo linkVideo={linkVideo} setLinkVideo={setLinkVideo} />
      <div className="w-100 h-100 position-relative">
        <video autoPlay loop muted className="videoBackground">
          <source src={videoHome2} type="video/mp4" />
          Seu navegador não suporta vídeos HTML5.
        </video>
        <div
          className="tituloHome tituloHome1"
          onClick={() => {
            setLinkVideo(
              "https://player.vimeo.com/video/809801504?h=d08d1a0f73"
            );
          }}
        >
          Sóciro
        </div>
        <div className="background"></div>
      </div>
    </>
  );
}
