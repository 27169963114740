import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import VimeoPlayer from "@vimeo/player";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalVideo({ linkVideo, setLinkVideo }) {
  const playerRef = useRef(null);

  useEffect(() => {
    if (linkVideo) {
      const player = new VimeoPlayer(playerRef.current);
      player.play();
    }
  }, [linkVideo]);

  return (
    <div className="posicaoModal">
      {linkVideo && (
        <div
          className="modal"
          onClick={() => {
            setLinkVideo("");
          }}
        >
          <CloseIcon
            className="fecharModal"
            onClick={() => {
              setLinkVideo("");
            }}
          />

          <div className="divVideoModal">
            <iframe
              className="videoModal"
              src={linkVideo}
              allow="autoplay; fullscreen; picture-in-picture"
              ref={playerRef}
            />
          </div>
        </div>
      )}
    </div>
  );
}
