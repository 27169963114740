import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("tttttttttttttttt", pathname);

    if (
      pathname !== "/diretores/erik-trovilho" &&
      pathname !== "/diretores/rapha-borges"
    )
      window.scrollTo(0, 0);
  }, [pathname]);

  return children;
}

export default ScrollToTop;
